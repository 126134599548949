<template>
  <!--身份與專長設定-->
  <div class="service-course-setting" :class="{ 'is-active': visible }">
    <div class="service-course-setting__overlay">
      <div class="service-course-setting__inner">
        <header class="service-course-setting__header">
          <h3>設定</h3>
          <button class="service-course-setting__btn-close" @click="onClose"></button>
        </header>
        <div class="service-course-setting__content">
          <h4>身份與專長設定</h4>
          <div class="service-course-setting__form">
            <div class="service-course-setting__row">
              <label for="identity">身份設定</label>
              <div class="service-course-setting__skill-wrap">
                <div class="employee-detail__skill-item" v-for="job in jobs" :key="job">
                  <input type="checkbox" :checked="selectedJobsId.includes(job.id)" @change="onJobChange(job.id)">
                  <label for="skill-01">{{job.name}}</label>
                </div>
              </div>

              <!-- <div class="service-course-setting__select-wrap">
                <select name="identity" id="identity" v-model="selectedJobId">
                  <option :value="job.id" v-for="job in jobs" :key="job">{{job.name}}</option>
                </select>
              </div> -->
            </div>
            <div class="service-course-setting__row">
              <label for="skill">專長設定</label>
              <div class="service-course-setting__skill-wrap">
                <div class="employee-detail__skill-item" v-for="specialty in specialties" :key="specialty">
                  <input type="checkbox" :checked="selectedSpecialtiesId.includes(specialty.id)" @change="onSpecialtyChange(specialty.id)">
                  <label for="skill-01">{{specialty.name}}</label>
                </div>
              </div>
            </div>
            <button class="admin-btn-lg" @click="onSave">確定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--身份與專長設定-->
</template>
<script>
import { apiGetJobs, apiGetSpecialties } from '@/api/v1/admin'

export default {
  name: 'JobAndSpecialtiesDialog',
  props: ['visible', 'defaultSelectedJobId', 'defaultSelectedSpecialties'],
  emits: ['close', 'save'],
  data () {
    return {
      jobs: '',
      specialties: '',
      selectedJobsId: this.defaultSelectedJobId,
      selectedSpecialtiesId: this.defaultSelectedSpecialties
    }
  },
  created () {
  },
  watch: {
    visible (val) {
      // turned visible
      if (val === true) {
        this.fetchData()
        this.selectedJobsId = this.defaultSelectedJobId
        this.selectedSpecialtiesId = this.defaultSelectedSpecialties
      }
    }
  },
  methods: {
    fetchData () {
      apiGetJobs().then(data => {
        console.log(data)
        if (!data.error) {
          this.jobs = data
        }
      })
      apiGetSpecialties().then(data => {
        console.log(data)
        if (!data.error) {
          this.specialties = data
        }
      })
    },
    onJobChange (jobId) {
      const i = this.selectedJobsId.indexOf(jobId)
      if (i === -1) {
        this.selectedJobsId.push(jobId)
      } else {
        this.selectedJobsId.splice(i, 1)
      }
    },
    onSpecialtyChange (specialtyId) {
      const i = this.selectedSpecialtiesId.indexOf(specialtyId)
      if (i === -1) {
        this.selectedSpecialtiesId.push(specialtyId)
      } else {
        this.selectedSpecialtiesId.splice(i, 1)
      }
    },
    onClose () {
      this.$emit('close')
    },
    onSave () {
      if (this.selectedJobsId.length === 0) {
        this.$swal('請勾選身份')
      } else if (this.selectedSpecialtiesId.length === 0) {
        this.$swal('請勾選專長')
      } else {
        this.$emit('save', { jobsId: this.selectedJobsId, specialtiesId: this.selectedSpecialtiesId })
      }
    }
  }
}
</script>
