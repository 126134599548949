<template>
  <div class="admin-wrap">
    <AdminMenu active-item="treatments" />
    <div class="admin-main">
      <AdminHeader title="服務管理：療程" backlink="javascript:history.back()" />
      <div class="service-course-add">
        <div class="service-course-add__basic-info">
          <div class="service-course-add__basic-info-inner">
            <div class="service-course-add__col">
              <h3>基本資料</h3>
              <div class="service-course-add__form">
                <div class="service-course-add__row">
                  <label for="name">項目名稱</label>
                  <input type="text" id="name" v-model="treatment.name">
                </div>
                <div class="service-course-add__row">
                  <label for="status">選擇類別</label>
                  <div class="service-course-add__select-wrap">
                    <select name="type" id="status" v-model="treatment.treatmentTypeId">
                      <option v-for="ttype in treatmentTypes" :key="ttype" :value="ttype.id">{{ttype.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="service-course-add__row">
                  <label for="status">醫/美療</label>
                  <div class="service-course-add__select-wrap">
                    <select name="type" id="status" v-model="treatment.isMedical">
                      <option value="true">醫療</option>
                      <option value="false">美療</option>
                    </select>
                  </div>
                </div>
                <div class="service-course-add__row">
                  <label for="max-count">同時最多人數</label>
                  <input type="text" id="max-count" v-model="treatment.maxConcurrentReservations">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="service-course-add__flow">
          <h3>服務流程管理</h3>
          <div class="service-course-add__flow-wrap">
            <div class="service-course-add__flow-item">
              <span class="service-course-add__step">1 報到</span>
              <div class="service-course-add__time-wrap">
                <input type="text" placeholder="時間" v-model="treatment.checkInTime">
              </div>
              <button class="admin-btn-add" @click="onSelectJobSpecialty('CHECKIN')">選擇身份與專長</button>
              <input type="text" placeholder="積分" v-model="treatment.checkInScore">
            </div>
            <div class="service-course-add__flow-item">
              <span class="service-course-add__step">2 卸洗</span>
              <div class="service-course-add__time-wrap">
                <input type="text" placeholder="時間" v-model="treatment.washTime">
              </div>
              <button class="admin-btn-add" @click="onSelectJobSpecialty('WASH')">選擇身份與專長</button>
              <input type="text" placeholder="積分" v-model="treatment.washScore">
            </div>
            <div class="service-course-add__flow-item">
              <span class="service-course-add__step">3 拍照</span>
              <div class="service-course-add__time-wrap">
                <input type="text" placeholder="時間" v-model="treatment.photoTime">
              </div>
              <button class="admin-btn-add" @click="onSelectJobSpecialty('PHOTO')">選擇身份與專長</button>
              <input type="text" placeholder="積分" v-model="treatment.photoScore">
            </div>
            <div class="service-course-add__flow-item">
              <span class="service-course-add__step">4 敷麻</span>
              <div class="service-course-add__time-wrap">
                <input type="text" placeholder="時間" v-model="treatment.anesthesiaTime">
              </div>
              <button class="admin-btn-add" @click="onSelectJobSpecialty('ANESTHESIA')">選擇身份與專長</button>
              <input type="text" placeholder="積分" v-model="treatment.anesthesiaScore">
            </div>
            <!-- 卸麻 -->
            <div class="service-course-add__flow-item">
              <span class="service-course-add__step">5 卸麻</span>
              <div class="service-course-add__time-wrap">
                <input type="text" placeholder="時間" v-model="treatment.postAnesthesiaTime">
              </div>
              <button class="admin-btn-add" @click="onSelectJobSpecialty('POST_ANESTHESIA')">選擇身份與專長</button>
              <input type="text" placeholder="積分" v-model="treatment.postAnesthesiaScore">
            </div>

            <div class="service-course-add__flow-item">
              <span class="service-course-add__step">6 醫師處置</span>
              <div class="service-course-add__time-wrap">
                <input type="text" placeholder="時間" v-model="treatment.doctorTime">
              </div>
              <button class="admin-btn-add" @click="onSelectJobSpecialty('DOCTOR')">選擇身份與專長</button>
              <input type="text" placeholder="積分" v-model="treatment.doctorScore">
            </div>
            <div class="service-course-add__flow-item">
              <span class="service-course-add__step">7-1 術後鎮定</span>
              <div class="service-course-add__time-wrap">
                <input type="text" placeholder="時間" v-model="treatment.sedativesTime">
                <!-- <input type="text" placeholder="時間2">
                <input type="text" placeholder="時間3"> -->
              </div>
              <button class="admin-btn-add" @click="onSelectJobSpecialty('SEDATIVES')">選擇身份與專長</button>
              <input type="text" placeholder="積分" v-model="treatment.sedativesScore">
            </div>
            <div class="service-course-add__flow-item">
              <span class="service-course-add__step">7-2 術後鎮定(卸面膜)</span>
              <div class="service-course-add__time-wrap">
                <input type="text" placeholder="時間" v-model="treatment.sedatives2Time">
                <!-- <input type="text" placeholder="時間2">
                <input type="text" placeholder="時間3"> -->
              </div>
              <button class="admin-btn-add" @click="onSelectJobSpecialty('SEDATIVES2')">選擇身份與專長</button>
              <input type="text" placeholder="積分" v-model="treatment.sedatives2Score">
            </div>
            <div class="service-course-add__flow-item">
              <span class="service-course-add__step">8 預約|衛教</span>
              <div class="service-course-add__time-wrap">
                <input type="text" placeholder="時間" v-model="treatment.appointmentTime">
              </div>
              <button class="admin-btn-add" @click="onSelectJobSpecialty('APPOINTMENT')">選擇身份與專長</button>
              <input type="text" placeholder="積分" v-model="treatment.appointmentScore">
            </div>
            <div class="service-course-add__flow-item">
              <span class="service-course-add__step">9 送客</span>
              <div class="service-course-add__time-wrap">
                <input type="text" placeholder="時間" v-model="treatment.byeTime">
              </div>
              <button class="admin-btn-add" @click="onSelectJobSpecialty('BYE')">選擇身份與專長</button>
              <input type="text" placeholder="積分" v-model="treatment.byeScore">
            </div>
          </div>
          <button class="admin-btn-lg" @click="onSave">儲存</button>
        </div>
        <!--連續指派設定-->
        <div class="service-course-add__continuous">
          <h3>連續指派設定</h3>
          <div class="service-course-add__continuous-wrap">
            <!--設定步驟-->
            <div class="service-course-add__setting">
              <h4>設定步驟</h4>
              <div class="service-course-add__setting-wrap">
                <div class="service-course-add__block">
                  <label for="">開始步驟</label>
                  <div class="service-course-add__select-wrap">
                    <select name="type" v-model="newTreatmentFlowSeries.beginFlow">
                      <option v-for="flow in treatmentFlows" :key="flow" :value="flow">{{flow}}</option>
                    </select>
                  </div>
                </div>
                <div class="service-course-add__block">
                  <label for="">結束步驟</label>
                  <div class="service-course-add__select-wrap">
                    <select name="type" v-model="newTreatmentFlowSeries.endFlow">
                      <option v-for="flow in treatmentFlows" :key="flow" :value="flow">{{flow}}</option>
                    </select>
                  </div>
                </div>
                <button class="admin-btn" @click="addTreatmentFlowSeries">新增</button>
              </div>
            </div>
            <!--設定步驟-->
            <!--已儲存的連續指派-->
            <div class="service-course-add__saved">
              <h4>已儲存的連續指派</h4>
              <div class="service-course-add__saved-wrap">
                <div class="service-course-add__saved-item" v-for="series in treatmentFlowSeries" :key="series">
                  <div>開始步驟：{{series.beginFlow}}</div>
                  <div>結束步驟：{{series.endFlow}}</div>
                  <button class="admin-btn" @click="deleteTreatmentFlowSeries(series.id)">刪除</button>
                </div>
              </div>
            </div>
            <!--已儲存的連續指派-->
          </div>
        </div>
        <!--連續指派設定-->
      </div>
    </div>
  </div>
  <job-and-sepcialties-dialog :visible="isShowDialog" :defaultSelectedJobId="selectedJobsId" :defaultSelectedSpecialties="selectedSpecialtiesId" @save="onDialogSave" @close="onDialogClose" />
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import JobAndSepcialtiesDialog from '../../components/JobAndSepcialtiesDialog.vue'
import {
  apiGetTreatmentTypes,
  apiGetTreatment,
  apiSaveTreatment,
  apiGetTreatmentJobsAndSpecialties,
  apiGetTreatmentFlowSeries,
  apiAddTreatmentFlowSeries,
  apiDeleteTreatmentFlowSeries
} from '@/api/v1/admin'

const flowNames = ['CHECKIN', 'WASH', 'PHOTO', 'ANESTHESIA', 'POST_ANESTHESIA', 'DOCTOR', 'SEDATIVES', 'SEDATIVES2', 'APPOINTMENT', 'BYE']

export default {
  name: 'TreatmentDetail',
  props: ['treatmentId'],
  components: { AdminMenu, AdminHeader, JobAndSepcialtiesDialog },
  created () {
    this.fetchData()
  },
  data () {
    return {
      treatmentFlows: ['報到', '卸洗', '拍照', '敷麻', '卸麻', '醫師處置', '術後鎮定', '術後鎮定(卸面膜)', '預約|衛教', '送客'],
      treatment: {
        id: null,
        name: '',
        treatmentTypeId: 1,
        maxConcurrentReservations: 1,
        checkInTime: 5,
        checkInScore: 5,
        washTime: 5,
        washScore: 5,
        photoTime: 5,
        photoScore: 5,
        anesthesiaTime: 5,
        anesthesiaScore: 5,
        postAnesthesiaTime: 0,
        postAnesthesiaScore: 0,
        doctorTime: 5,
        doctorScore: 5,
        sedativesTime: 5,
        sedativesScore: 5,
        sedatives2Time: 0,
        sedatives2Score: 0,
        appointmentTime: 5,
        appointmentScore: 5,
        byeTime: 5,
        byeScore: 0
      },
      treatmentTypes: '',
      isShowDialog: false,
      selectedFlow: 0,
      jobAndSepcialties: {},
      treatmentFlowSeries: [],
      newTreatmentFlowSeries: {}
    }
  },
  computed: {
    selectedJobsId () {
      return this.jobAndSepcialties[this.selectedFlow]?.jobsId || []
    },
    selectedSpecialtiesId () {
      return this.jobAndSepcialties[this.selectedFlow]?.specialtiesId || []
    }
  },
  methods: {
    fetchData () {
      if (this.treatmentId > 0) {
        apiGetTreatment(this.treatmentId).then(data => {
          // console.log(data)
          if (data.error) {
            console.log(data.error.message)
          } else {
            this.treatment = data
            this.getTreatmentJobsAndSpecialties()
          }
        })
        this.getTreatmentFlowSeries()
      }
      apiGetTreatmentTypes().then(data => {
        if (!data.error) {
          this.treatmentTypes = data
        }
      })
    },
    getTreatmentFlowSeries () {
      apiGetTreatmentFlowSeries(this.treatmentId).then(data => {
        // console.log(data)
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.treatmentFlowSeries = data
        }
      })
    },
    getTreatmentJobsAndSpecialties () {
      if (this.treatment.id) {
        apiGetTreatmentJobsAndSpecialties(this.treatment.id).then(data => {
          console.log(data)
          // const flowNames = ['CHECKIN', 'WASH', 'PHOTO', 'ANESTHESIA', 'DOCTOR', 'SEDATIVES', 'APPOINTMENT']
          if (!data.error) {
            flowNames.forEach(flowName => {
              const js = {
                jobsId: data.jobsId.filter(d => d.flow === flowName).map(j => j.id),
                specialtiesId: data.specialtiesId.filter(d => d.flow === flowName).map(s => s.id)
              }
              this.jobAndSepcialties[flowName] = js
            })
          }
        })
      }
    },
    onSelectJobSpecialty (id) {
      this.isShowDialog = true
      this.selectedFlow = id
    },
    onDialogSave (data) {
      this.jobAndSepcialties[this.selectedFlow] = data
      this.onDialogClose()
    },
    onDialogClose () {
      this.isShowDialog = false
      this.selectedFlow = 0
    },
    onSave () {
      const jobsId = []
      const specialtiesId = []
      for (const [flow, js] of Object.entries(this.jobAndSepcialties)) {
        js.jobsId.forEach(jid => jobsId.push({ flow: flow, id: jid }))
        js.specialtiesId.forEach(sid => specialtiesId.push({ flow: flow, id: sid }))
      }
      const params = {
        treatment: this.treatment,
        jobsAndSepcialties: {
          jobsId: jobsId,
          specialtiesId: specialtiesId
        }
      }
      console.log(JSON.parse(JSON.stringify(params)))
      apiSaveTreatment(this.treatmentId, params).then(data => {
        console.log(data)
        if (data.error) {
          this.$swal('儲存失敗', data.message, 'warning')
        } else {
          this.treatment = data
          this.$swal('儲存完成', '', 'success')
        }
      }).catch(err => {
        this.$swal('儲存失敗', err.data.error, 'warning')
      })
    },
    addTreatmentFlowSeries () {
      this.newTreatmentFlowSeries.treatmentId = this.treatmentId
      apiAddTreatmentFlowSeries(this.newTreatmentFlowSeries).then(data => {
        console.log(data)
        if (data.error) {
          this.$swal('新增失敗', data.message, 'warning')
        } else {
          this.getTreatmentFlowSeries()
        }
      })
    },
    deleteTreatmentFlowSeries (seriesId) {
      apiDeleteTreatmentFlowSeries(this.treatmentId, seriesId).then(data => {
        console.log(data)
        if (data.error) {
          this.$swal('刪除失敗', data.message, 'warning')
        } else {
          this.getTreatmentFlowSeries()
        }
      })
    }
  }
}
</script>

<style>

</style>
